<template>
  <div class="container" style="max-width: 750px">

    <div class="card">

      <header class="card-header">
        <p class="card-header-title">
          <b-icon icon="pencil" custom-size="default"/>
          <span>Details</span>
        </p>
      </header>
      <div class="card-content">
        <div class="media">
          <div class="media-content header-media-content ">
            <p class=" has-text-weight-bold">Status:</p>
            <StatusComponent :status="submission.status" class="level-item"></StatusComponent>

            <p class="pt-2 has-text-weight-bold">Aangemaakt op:</p>
            <p class="pb-2">
              {{ submission.created_at | moment("from", "now") }}
              <small>({{ submission.created_at | moment("DD-MM-YYYY HH:mm:ss") }})</small>

            </p>
            <p class=" has-text-weight-bold">Toegevoegd door:</p>
            <p class="pb-1" v-if="submission.user">
              {{ submission.user.first_name }}
            </p>

            <div v-if="isadmin">
              <hr>
              <div v-if="submission.status === 'pending'" class="buttons">
                <b-button @click="approve" type="is-success" outlined icon-left="check" expanded>Accepteer</b-button>
                <b-button @click="refuse" type="is-danger" outlined icon-left="close" expanded>Wijs af</b-button>
              </div>
              <div v-else-if="submission.status === 'approved'">
                Aanvraag goedgekeurd op xxx
              </div>
              <div v-else-if="submission.status === 'refused'">
                Aanvraag afgekeurd op xxx
              </div>
              <div v-else>
                Je kan een aanvraag goed of afkeuren zodra hij gefinaliseerd is.
              </div>
            </div>


          </div>
        </div>
      </div>
      <footer class="card-footer">
        <a class="card-footer-item" slot="footer"
           @click.prevent="$router.push({ name:'submission.onboarding', params: {id: submission.id, read: true}})">
          <b-icon icon="eye" custom-size="default"/>
          <span>Bekijk aanvraag</span>
        </a>

         <a v-if="submission.status !== 'approved'" class="card-footer-item" slot="footer"
           @click.prevent="$router.push({ name:'submission.onboarding', params: {id: submission.id, read: false}})">
          <b-icon icon="pencil" custom-size="default"/>
          <span>Wijzig aanvraag</span>
        </a>

      </footer>
    </div>
    <br>
    <br>

    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon icon="chat" custom-size="default"/>
          <span>Comments</span>
        </p>
      </header>
      <div class="card-content">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img src="https://bulma.io/images/placeholders/128x128.png">
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Barbara Middleton</strong>
                <br>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porta eros lacus, nec ultricies elit
                blandit non. Suspendisse pellentesque mauris sit amet dolor blandit rutrum. Nunc in tempus turpis.
                <br>
                <small><a>Like</a> · <a>Reply</a> · 3 hrs</small>
              </p>
            </div>

            <article class="media">
              <figure class="media-left">
                <p class="image is-48x48">
                  <img src="https://bulma.io/images/placeholders/96x96.png">
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Sean Brown</strong>
                    <br>
                    Donec sollicitudin urna eget eros malesuada sagittis. Pellentesque habitant morbi tristique senectus
                    et netus et malesuada fames ac turpis egestas. Aliquam blandit nisl a nulla sagittis, a lobortis leo
                    feugiat.
                    <br>
                    <small><a>Like</a> · <a>Reply</a> · 2 hrs</small>
                  </p>
                </div>

                <article class="media">
                  Vivamus quis semper metus, non tincidunt dolor. Vivamus in mi eu lorem cursus ullamcorper sit amet nec
                  massa.
                </article>

                <article class="media">
                  Morbi vitae diam et purus tincidunt porttitor vel vitae augue. Praesent malesuada metus sed pharetra
                  euismod. Cras tellus odio, tincidunt iaculis diam non, porta aliquet tortor.
                </article>
              </div>
            </article>

            <article class="media">
              <figure class="media-left">
                <p class="image is-48x48">
                  <img src="https://bulma.io/images/placeholders/96x96.png">
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Kayli Eunice </strong>
                    <br>
                    Sed convallis scelerisque mauris, non pulvinar nunc mattis vel. Maecenas varius felis sit amet magna
                    vestibulum euismod malesuada cursus libero. Vestibulum ante ipsum primis in faucibus orci luctus et
                    ultrices posuere cubilia Curae; Phasellus lacinia non nisl id feugiat.
                    <br>
                    <small><a>Like</a> · <a>Reply</a> · 2 hrs</small>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </article>
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img src="https://bulma.io/images/placeholders/128x128.png">
            </p>
          </figure>
          <div class="media-content">
            <div class="field">
              <p class="control">
                <textarea class="textarea" placeholder="Add a comment..."></textarea>
              </p>
            </div>
            <div class="field">
              <p class="control">
                <button class="button">Post comment</button>
              </p>
            </div>
          </div>
        </article>
      </div>

    </div>


  </div>
</template>

<script>
import StatusComponent from "@/components/StatusComponent";

export default {
  name: "SubmissionDetail",
  components: {StatusComponent},
  data() {
    return {
      submission: {
        name: "loading..."
      }
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    },
    isadmin() {
      return this.$store.state.user?.admin
    },
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchSubmissionDetails", this.id).then(response => {
        this.submission = response.data
        this.$store.dispatch('setTitle', {
          message: this.submission.name,
          back: true,
          stack: [
            {
              message: response.data.name,
            }
          ]
        })

      })
    },

    submit() {
      this.$store.dispatch("storeSubmissionData", {
        'id': this.submission,
        'data': {_1_requesting_entity: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
      })
    },
    approve() {
      this.$store.dispatch("approveSubmission", this.submission.id).then(() => {
        this.fetchData()
        this.$buefy.toast.open({
          message: 'Aanvraag goedgekeurd',
          type: 'is-success'
        })
      }).catch(error => {
        this.$buefy.toast.open({
          message: error.response.message,
          type: 'is-danger'
        })

      })
    },
    refuse() {
      this.$store.dispatch("refuseSubmission", this.submission.id).then(() => {
        this.fetchData()
        this.$buefy.toast.open({
          message: 'Aanvraag afgewezen',
          type: 'is-success'
        })
      }).catch(error => {
        this.$buefy.toast.open({
          message: error.response.message,
          type: 'is-danger'
        })

      })
    },


  }

}
</script>

<style scoped>

</style>