<template>
  <b-tag :type="result.type" size="is-medium">
    {{ result.msg }}
  </b-tag>
</template>

<script>
export default {
  name: "StatusComponent",
  props: ["status"],
  computed: {
    result() {
      if(this.status === "draft"){
        return {
          msg: "Concept",
          type: "is-grey-dark is-light"
        }}
      else if(this.status === "pending"){
        return {
          msg: "Ingediend",
          type: "is-warning is-light"
        }
      }
      else if(this.status === "approved"){
        return {
          msg: "Goedgekeurd",
          type: "is-success is-light"
        }
      }
      else if(this.status === "refused"){
        return {
          msg: "Afgewezen",
          type: "is-danger is-light"
        }
      }
      else return {
          msg: "Onbekend",
          type: "is-grey"
        }
    }


  }
}
</script>

<style scoped>

</style>